.techstack {
  padding-left: 130px;
  margin-top: 60px;
}
.techstack hr {
  color: #138781;
  width: 400px;
  margin: 10px auto;
}

.techstack h2 {
  font-weight: bold;
  color: #138781;
}

.techstack .card {
  cursor: pointer;
}

.techstack .tech-icon {
  font-size: 2rem;
  margin-right: 10px;
}

.techstack .card:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background-color: #138781;
  color: white;

  transition: 1s;
}

/* =============================== */
@media (max-width: 600px) {
  .techstack {
    padding: 0;
    margin: 0;
    margin-top: 50px;
  }

  .techstack hr {
    width: 280px;
  }
}
