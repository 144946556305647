.mobile-nav {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.mobile-nav-header {
  height: 50px;
  width: 100%;
  background-color: #1e1e1e;
  padding: 8px;
}

.mobile-nav-header .mobile-nav-icon {
  color: white;
  cursor: pointer;
}

.mobile-nav-title {
  margin-left: 20px;
  color: #f29f67;
  font-weight: bold;
  text-transform: capitalize;
  vertical-align: middle;
  font-size: 20px;
}

.mobile-nav-menu .nav-items {
  height: auto;
  width: 200px;
  background-color: #1e1e1e;
  padding: 10px;
  padding-bottom: 20px;
  border-bottom-right-radius: 10px;
}

@media (min-width: 600px) {
  .mobile-nav {
    display: none;
  }
}
